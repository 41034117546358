import unionBy from 'lodash/unionBy';
import { $path } from 'remix-routes';

import {
  type DtoPage,
  type DtoPageRow,
  type DtoPageSection,
  type DtoTag,
  EnumsPageName,
  EnumsPageRowType,
  type ModelsPageRow,
  type ModelsPageSection,
  type ModelsSettingsPage,
} from '@lp-lib/api-service-client/public';

import { assertExhaustive } from '../../utils/common';

export class PageManagerUtils {
  static GetRows(page: DtoPage): DtoPageRow[] {
    const rows: DtoPageRow[] = [];
    if (page.rows) {
      rows.push(...page.rows);
    }
    if (page.sections) {
      for (const section of page.sections) {
        if (!section.rows) continue;
        rows.push(...section.rows);
      }
    }
    return rows;
  }

  static GetGamePackTags(page: DtoPage) {
    const rows = this.GetRows(page);

    const tags: DtoTag[] = [];
    for (const row of rows) {
      if (row.type === EnumsPageRowType.PageRowTypeTag && row.tag) {
        tags.push(row.tag);
      }
    }

    return unionBy(tags, (tag) => tag.id);
  }

  static IsPageName(name: string | null | undefined): name is EnumsPageName {
    return Object.values(EnumsPageName).includes(name as EnumsPageName);
  }

  static GetLoggedInPath(name: EnumsPageName) {
    switch (name) {
      case EnumsPageName.PageNamePublicHome:
        return $path('/home');
      case EnumsPageName.PageNameLiveLoggedIn:
        return $path('/live-events');
      default:
        return null;
    }
  }

  static GetLoggedOutPath(name: EnumsPageName) {
    switch (name) {
      case EnumsPageName.PageNameExplore:
        return $path('/explore');
      case EnumsPageName.PageNameLiveOtp:
        return $path('/live-events');
      default:
        return null;
    }
  }

  static GetTags(page: DtoPage): DtoTag[] {
    const tags: DtoTag[] = [];
    for (const row of page.rows || []) {
      tags.push(...PageRowUtils.GetTags(row));
    }
    for (const section of page.sections || []) {
      tags.push(...PageSectionUtils.GetTags(section));
    }
    return tags;
  }

  static DTOToModel(dto: DtoPage): ModelsSettingsPage {
    const sections = dto.sections?.map(PageSectionUtils.DTOToModel);
    const rows = dto.rows?.map(PageRowUtils.DTOToModel);

    return {
      ...dto,
      sections,
      rows,
    };
  }
}

export class PageSectionUtils {
  static GetTags(section: DtoPageSection): DtoTag[] {
    const tags: DtoTag[] = [];
    for (const row of section.rows || []) {
      tags.push(...PageRowUtils.GetTags(row));
    }
    return tags;
  }

  static HasTag(section: DtoPageSection, tagId: number): boolean {
    const tags = this.GetTags(section);
    return tags.some((tag) => tag.id === tagId);
  }

  static DTOToModel(dto: DtoPageSection): ModelsPageSection {
    const rows = dto.rows?.map(PageRowUtils.DTOToModel) || [];

    return {
      ...dto,
      rows,
    };
  }
}

export class PageRowUtils {
  static GetTags(row: DtoPageRow): DtoTag[] {
    const tags: DtoTag[] = [];
    if (!!row.tag) tags.push(row.tag);
    if (!!row.tagCollection?.tags) tags.push(...row.tagCollection.tags);
    return tags;
  }

  static DTOToModel(dto: DtoPageRow): ModelsPageRow {
    switch (dto.type) {
      case EnumsPageRowType.PageRowTypeTagCollections:
        return {
          type: dto.type,
          id: dto.id,
          layout: dto.layout,
          anchor: dto.anchor,
          tagCollection: {
            name: dto.tagCollection?.name || '',
            ids: dto.tagCollection?.tags?.map((tag) => String(tag.id)) || [],
          },
        };
      case EnumsPageRowType.PageRowTypeTag:
      case EnumsPageRowType.PageRowTypeFeaturedCarousel:
      case EnumsPageRowType.PageRowTypeSocialProof:
        return {
          id: dto.id,
          type: dto.type,
          layout: dto.layout,
          anchor: dto.anchor,
        };
      default:
        assertExhaustive(dto.type);
        return {
          type: dto.type,
          id: dto.id,
          layout: dto.layout,
          anchor: dto.anchor,
        };
    }
  }
}
